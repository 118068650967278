import { HomeroomTeacherType } from '@src/Models/homeromTeacher';

export const homeRoomDetails = (data?: HomeroomTeacherType | undefined): { label: string; value?: string }[] => {
  return [
    {
      label: 'homeRoomTeacherID',
      value: data?.id,
    },
    {
      label: 'name',
      value: data?.home_room,
    },
    {
      label: 'assignedTo',
      value: `${data?.assigned_to?.first_name} ${data?.assigned_to?.last_name}`,
    },
    {
      label: 'assignedBy',
      value: `${data?.assigned_by?.first_name} ${data?.assigned_by?.last_name}`,
    },
    {
      label: 'dueDate',
      value: data?.dueDate,
    },
    {
      label: 'createdAt',
      value: data?.created_at,
    },
    {
      label: 'updatedAt',
      value: data?.updated_at,
    },
  ];
};
