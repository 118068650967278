import apiEndpoints from '@src/lib/apiEndpoints';

import apiRequest from './api';
import { GetTableFilterProps } from './type';

export interface SchoolLevelProductData {
  id: string;
  count: number;
  name: string;
}

export const getSchoolLevel = (params: GetTableFilterProps): Promise<{ data: SchoolLevelProductData }> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.MERCHANDISE_SCHOOL_LEVEL}`,
    config: { params },
  });
};
