import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Clear, MoreVert } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';

import { StyledMenu } from '@src/lib/styles/globalWrapper';

import { SelectedColumnsType, TableColumnFilterLayoutProps } from '../type';

export const TableColumnFilterLayout: React.FC<TableColumnFilterLayoutProps> = ({
  headers,
  handleApplyColumnFilter,
}) => {
  const [anchorFilter, setAnchorFilter] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const initialColumns = headers.reduce((cols, column) => {
    cols[column.name] = !column.hideInitially;

    return cols;
  }, {} as SelectedColumnsType);

  const [selectedColumns, setSelectedColumns] = useState<SelectedColumnsType>(initialColumns);

  const handleColumnChange = (column: string, checked: boolean) => {
    setSelectedColumns(prevSelectedColumns => ({
      ...prevSelectedColumns,
      [column]: checked,
    }));
  };

  const handleSelectAllColumns = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectedColumns = Object.keys(selectedColumns).reduce((cols, column) => {
      cols[column] = event.target.checked;
      return cols;
    }, {} as SelectedColumnsType);

    setSelectedColumns(newSelectedColumns);
  };

  const applyFilters = () => {
    handleApplyColumnFilter(selectedColumns);
  };

  const resetFilters = () => {
    setSelectedColumns(initialColumns);
    handleApplyColumnFilter('reset');
  };

  const handleCloseFilterMenu = () => {
    setAnchorFilter(null);
  };

  const handleOpenFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorFilter(event.currentTarget);
  };

  const areAllColumnsSelected = Object.values(selectedColumns).every(value => value);

  return (
    <Box>
      <Tooltip title={t('selectColumns')}>
        <IconButton onClick={handleOpenFilterMenu}>
          <MoreVert sx={{ color: anchorFilter ? 'skyblue' : 'inherit' }} />
        </IconButton>
      </Tooltip>
      <StyledMenu
        id='menu-appbar'
        anchorEl={anchorFilter}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorFilter)}
        onClose={handleCloseFilterMenu}
      >
        <div style={{ maxWidth: '400px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography style={{ marginLeft: '10px' }} fontWeight='500'>
              {t('selectColumns')}
            </Typography>
            <IconButton onClick={handleCloseFilterMenu}>
              <Clear />
            </IconButton>
          </div>
          <div style={{ margin: '0 10px', display: 'flex', flexDirection: 'column' }}>
            <FormControl component='fieldset' style={{ maxHeight: '300px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={areAllColumnsSelected}
                    indeterminate={!areAllColumnsSelected && Object.values(selectedColumns).some(value => value)}
                    onChange={handleSelectAllColumns}
                  />
                }
                label={t('selectAll')}
              />
              <RadioGroup style={{ maxHeight: '50vh', overflowX: 'auto' }}>
                {headers.map(column => (
                  <FormControlLabel
                    key={column.name}
                    control={
                      <Checkbox
                        checked={selectedColumns[column.name]}
                        onChange={event => handleColumnChange(column.name, event.target.checked)}
                      />
                    }
                    label={t(column.label || '')}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
              <Button variant='outlined' color='primary' onClick={resetFilters}>
                {t('resetColumns')}
              </Button>
              <Button variant='contained' color='primary' onClick={applyFilters}>
                {t('applyColumns')}
              </Button>
            </div>
          </div>
        </div>
      </StyledMenu>
    </Box>
  );
};
