import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterAlt } from '@mui/icons-material';
import { Badge, Box, IconButton, Tooltip } from '@mui/material';

import TableFilterModal from '@src/components/Modals/FilterModal';

import { TableFilterLayoutProps } from '../type';

export const TableFilterLayout: React.FC<TableFilterLayoutProps> = ({ applyFilters, filterSchema, filterCount }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleCloseFilterModal = () => setIsOpen(false);

  const handleOpenFilterModal = () => setIsOpen(true);

  return (
    <Box>
      <Tooltip title={t('filters')}>
        <IconButton sx={{ mr: 2 }} onClick={handleOpenFilterModal}>
          <Badge badgeContent={filterCount} color='primary'>
            <FilterAlt sx={{ color: isOpen ? 'skyblue' : 'inherit' }} />
          </Badge>
        </IconButton>
      </Tooltip>

      <TableFilterModal
        isOpen={isOpen}
        handleClose={handleCloseFilterModal}
        applyFilters={applyFilters}
        filterSchema={filterSchema}
      />
    </Box>
  );
};
