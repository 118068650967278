import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { IconButton, Tooltip } from '@mui/material';

import { VisuallyHiddenInput } from '../style';
import { TableUploadButtonLayoutProps } from '../type';

export const TableUploadButtonLayout = ({ handleUploadFile }: TableUploadButtonLayoutProps) => {
  const { t } = useTranslation();

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const fileInput = e.target;

    if (!fileInput || !fileInput.files || fileInput.files.length === 0) {
      return;
    }

    const file = fileInput.files[0];
    const fileType = getFileType(file.name);

    if (fileType !== 'csv' && fileType !== 'xlsx') {
      console.log('Invalid file format. Please upload a CSV or XLSX file.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    handleUploadFile?.(formData);
  };

  const getFileType = (fileName: string): string => {
    const splitFileName = fileName.split('.');
    return splitFileName.length > 1 ? splitFileName.pop()!.toLowerCase() : '';
  };

  if (!handleUploadFile) return null;

  return (
    <Tooltip title={t('upload')}>
      <IconButton component='label' htmlFor='icon-button-file'>
        <VisuallyHiddenInput
          id='icon-button-file'
          type='file'
          accept='.csv, .xlsx'
          onChange={handleFileChange}
          onClick={(event: React.MouseEvent<HTMLInputElement>) => {
            const input = event.target as HTMLInputElement;
            input.value = '';
          }}
        />
        <CloudUploadIcon />
      </IconButton>
    </Tooltip>
  );
};
