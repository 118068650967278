import apiEndpoints from '@src/lib/apiEndpoints';
import { FormControllerDataReturn } from '@src/lib/types';
import { HomeroomTeacherResponse, HomeroomTeacherType } from '@src/Models/homeromTeacher';
import { ResponseData } from '@src/screens/Staff/type';

import apiRequest from './api';
import { GetTableFilterProps } from './type';

export const getHomeroomTeachers = (params: GetTableFilterProps): Promise<ResponseData<HomeroomTeacherResponse>> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.HOME_ROOM}`,
    config: { params },
  });
};

export const getHomeroomTeacherById = async (id: string): Promise<HomeroomTeacherType> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.HOME_ROOM}/${id}`,
  });
};

export const assignHomeRoomTeacher = async (data: FormControllerDataReturn) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.HOME_ROOM}`,
    data: data,
  });
};

export const deleteAssignHomeRoomTeacher = async (id: string) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.HOME_ROOM}/${id}`,
  });
};
