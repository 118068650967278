import {
  AccountBalance,
  AccountBalanceWallet,
  Category,
  ExitToApp,
  Group,
  HistoryEdu,
  LocalCafe,
  ReceiptLong,
  Store,
} from '@mui/icons-material';
import GradeIcon from '@mui/icons-material/Grade';

import { HeadersType } from '@src/contexts/AuthContextProvider';
import { PermissionsType } from '@src/Models/modules';

import { lightModeColors } from './styles/colors';
import { AsaHeader, DrawerItem, Language } from './types';

export const Routes = {
  DASHBOARD: '/dashboard',
  LOGIN: '/login',
  LOGIN_OTP: '/login/otp',
  LOGIN_RECOVERY_CODE: '/recovery-code',
  USERS: '/users',
  PO: '/po',
  TWO_FA: '/2fa',
  ASA: '/asa',
  LOGS: '/logs',
  PAYMENT: '/thankyou',
  GENERAL: '/general',
  MERCHANDISE: '/merchandise',
  REPORTS: '/reports',
};

export const MerchandiseSubRoutes = {
  PRODUCTS: '/products',
  DASHBOARD: '/dashboard',
  MASTER: '/master',
  STORE: '/store',
  CART: '/cart',
  ORDERS: '/orders',
  MERCHANDISE_CHECKOUT: '/mo-checkout',
};

export const GeneralSubRoutes = {
  PAYMENT_LINKS: '/payment-links',
};

export const UsersSubRoutes = {
  MANAGE_CONTRACTOR: '/contractors',
  MANAGE_STAFF: '/staffs',
  MANAGE_HOME_ROOM: '/homeroom',
  MANAGE_PARENT: '/parents',
  MANAGE_STUDENT: '/students',
  MANAGE_ADMIN: '/admins',
  MANAGE_STAFF_BY_ID: '/staffs/:id',
  MANAGE_HOME_ROOM_BY_ID: '/homeroom/:id',
  MANAGE_ALL_USERS: '/all-users',
  ROLES: '/roles',
};

export const AsaSubRoutes = {
  PROGRAMS: '/programs',
  ACTIVITY: '/activity',
  MAPPING: '/mapping',
  MASTER: '/master',
  SUBMISSIONS: '/submissions',
  STUDENT_SCHEDULE: '/student-schedule',
  WAITING_LIST: '/waiting-list',
  SIGN_UP: '/program-signup',
  SCHEDULING: '/schedule',
  HOME_ROOM: '/home-room',
  GOOGLE_SHEET_SYNC: '/google-sheet-sync',
};

export const ReportsSubRoutes = {
  ATTENDANCE_SHEET: '/attendance-sheet',
  ACTIVITY_ENROLLMENTS: '/activity-enrollments',
  PAYMENT_TRANSACTIONS: '/transactions',
  WEEKLY_SCHEDULE: '/weekly-schedule',
  DAYWISE_PROGRAM_SCHEDULE: '/daywise-program-schedule',
  WAITING_LIST: '/waiting-list',
  PROGRAM_SUBMISSIONS: '/program-submissions',
};

export const AsaMasterSubCategory = {
  SESSION: '/session',
  SEASON: '/season',
  CATEGORY: '/category',
  MAPPING: '/mapping',
};

export const PoSubRoutes = {
  COST_CENTRES: '/cost-centres',
  VENDORS: '/vendors',
  ORGANIZATION_SETTINGS: '/organization-settings',
  PURCHASE_ORDERS: '/purchase-orders',
  PURCHASE_ORDERS_REVIEW: '/review-purchase-orders',
  APPROVAL_RULES: '/approval-rules',
  MASTER: '/master',
};

export const ApprovalRulesSubCatergory = {
  USERS: '/users',
  AMOUNT: '/amount',
};

export const PoMasterSubCategory = {
  CATEGORY: '/category',
  TYPE: '/type',
};

export const MerchandiseMasterSubCategory = {
  CATEGORY: '/category',
  ATTRIBUTES: '/attributes',
};

export const LogsSubRoutes = {
  SYNC_USERS: '/sync-users',
  API_REQUESTS: '/api-requests',
  PAYEMENT_TRANSACTIONS: '/payment-transactions',
};

export const RouteActions = {
  CREATE: '/create',
  EDIT: '/edit',
  ENABLE: '/enable',
  DISABLE: '/disable',
};

export const drawerWidth = 250;

export const DrawerList = [
  { text: DrawerItem.ASA, Icon: AccountBalance },
  { text: DrawerItem.PO, Icon: ReceiptLong },
  { text: DrawerItem.MERCHANDISE, Icon: Store },
  { text: DrawerItem.GATE, Icon: ExitToApp },
  { text: DrawerItem.CAFE, Icon: LocalCafe },
  { text: DrawerItem.WALLET, Icon: AccountBalanceWallet },
  { text: DrawerItem.USERS, Icon: Group },
  { text: DrawerItem.GRADES, Icon: GradeIcon },
  { text: DrawerItem.REPORTS, Icon: AccountBalance },
  { text: DrawerItem.LOGS, Icon: HistoryEdu },
  { text: DrawerItem.GENERAL, Icon: Category },
];

const ASAHeaderList = [
  'programs',
  'activity',
  'mapping',
  'schedule',
  'submissions',
  'student-schedule',
  'waiting-list',
  'google-sheet-sync',
  'master',
];

const POHeaderList = [
  'purchase-orders',
  'review-purchase-orders',
  'cost-centres',
  'vendors',
  'organization-settings',
  'approval-rules',
  'master',
];

const UsersHeaderList = [
  'staffs',
  'parents',
  'students',
  'homeroom',
  'contractors',
  'admins',
  'all-users',
  'roles',
];

const MerchandiseHeaderList = ['products', 'orders', 'transactions', 'reports', 'master'];

const LogsHeaderList = ['sync-users', 'api-requests', 'payment-transactions'];

const GeneralHeaderList = ['payment-links'];

const ReportsHeaderList = [
  'attendance-sheet',
  'activity-enrollments',
  'transactions',
  'weekly-schedule',
  'daywise-program-schedule',
  'waiting-list',
  'program-submissions',
];

const getHeaderList = (selectedModule: DrawerItem) => {
  switch (selectedModule) {
    case 'asa':
      return ASAHeaderList;

    case 'po':
      return POHeaderList;

    case 'users':
      return UsersHeaderList;

    case 'merchandise':
      return MerchandiseHeaderList;

    case 'reports':
      return ReportsHeaderList;

    case 'logs':
      return LogsHeaderList;

    case 'general':
      return GeneralHeaderList;

    default:
      return false;
  }
};

export const HeaderList = ({
  headers,
  selectedModule,
  isHomeRoom,
}: {
  headers: HeadersType;
  selectedModule: DrawerItem;
  isHomeRoom?: boolean;
}): string[] | false => {
  if (!Object.values(DrawerItem).includes(selectedModule)) return false;

  const list = getHeaderList(selectedModule);

  if (!list) return false;

  if (!headers) return false;

  if (headers === 'super_admin') return list;

  if (!headers[selectedModule]) return false;

  const selectedKeys = Object.keys(headers[selectedModule]);
  const allowedRoutes = list.filter(item => selectedKeys.includes(item));

  if (selectedModule === DrawerItem.ASA && isHomeRoom) {
    allowedRoutes.push(AsaHeader.HOME_ROOM);
  }

  return allowedRoutes;
};

export const LanguagesList = [
  { text: 'English', value: Language.EN },
  { text: 'Français', value: Language.FR },
  { text: '한국인', value: Language.KO },
  { text: '日本語', value: Language.JA },
];

export const Languages = Object.values(Language);

export const gradeOptions = [
  { label: 'EY', value: 'EY' },
  { label: 'KG', value: 'KG' },
  { label: 'G1', value: 'G1' },
  { label: 'G2', value: 'G2' },
  { label: 'G3', value: 'G3' },
  { label: 'G4', value: 'G4' },
  { label: 'G5', value: 'G5' },
  { label: 'G6', value: 'G6' },
  { label: 'G7', value: 'G7' },
  { label: 'G8', value: 'G8' },
  { label: 'G9', value: 'G9' },
  { label: 'G10', value: 'G10' },
  { label: 'G11', value: 'G11' },
  { label: 'G12', value: 'G12' },
];

export const schoolLevelOptions = [
  { label: 'Elementary School', value: '3' },
  { label: 'Middle School', value: '4' },
  { label: 'High School', value: '5' },
];

export const genderOptions = [
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Female',
    value: 'FEMALE',
  },
  {
    label: 'Both',
    value: 'BOTH',
  },
];

export const availabilityOptions = [
  {
    label: 'Parents',
    value: 'PARENTS',
  },
  {
    label: 'Students',
    value: 'STUDENTS',
  },
  {
    label: 'Staff',
    value: 'STAFF',
  },
  {
    label: 'Everyone',
    value: 'EVERYONE',
  },
];

export const weekDaysOptions = [
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' },
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
  { label: 'Saturday', value: 'saturday' },
];

export const orderStatusOptions = [
  { label: 'In Progress', value: 'IN_PROGRESS' },
  { label: 'Awaiting Pickup', value: 'AWAITING_PICKUP' },
  { label: 'On Hold', value: 'ON_HOLD' },
  { label: 'Delivered', value: 'DELIVERED' },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Canceled', value: 'CANCELED' },
  { label: 'Returned', value: 'RETURNED' },
];

export const orderPaymentStatusOptions = [
  { label: 'Pending', value: 'PENDING' },
  { label: 'Zero Fee', value: 'ZERO_FEE' },
  { label: 'Paid', value: 'PAID' },
  { label: 'Partially Paid', value: 'PARTIAL_PAID' },
  { label: 'Overpaid', value: 'OVERPAID' },
  { label: 'Failed', value: 'FAILED' },
  { label: 'Manually Paid', value: 'MANUALLY_PAID' },
];

export const fileSchemaLinks = {
  contractor: ['contactor/template/contactor_sample_csv.csv', 'contactor/template/contactor_sample_xlsx'],
};

export const defaultImage = '/icons/profileLogo.svg';

export enum ProcessStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ENROLLED = 'ENROLLED',
  CONFIRMED = 'CONFIRMED',
  ON_HOLD = 'ON_HOLD',
}

export enum PaymentStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  FAILED = 'FAILED',
  ZERO_FEE = 'ZERO_FEE',
  PARTIAL_PAID = 'PARTIAL_PAID',
  OVERPAID = 'OVERPAID',
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
}

export enum POStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ON_HOLD = 'ON_HOLD',
}

export const statusColors = {
  [ProcessStatus.PENDING_APPROVAL]: lightModeColors.lavenderMist,
  [ProcessStatus.ENROLLED]: lightModeColors.appleGreen,
  [ProcessStatus.APPROVED]: lightModeColors.limeGreen,
  [ProcessStatus.REJECTED]: lightModeColors.redOrange,
  [ProcessStatus.CONFIRMED]: lightModeColors.navyBlue,
  [PaymentStatus.PENDING]: lightModeColors.brightCyan,
  [PaymentStatus.PAID]: lightModeColors.appleGreen,
  [PaymentStatus.FAILED]: lightModeColors.cinnabar,
  [PaymentStatus.ACTIVE]: lightModeColors.appleGreen,
  [PaymentStatus.INACTIVE]: lightModeColors.redOrange,
};

export const gradeMapping = {
  '-1': 'EY',
  '0': 'KG',
  '1': 'G1',
  '2': 'G2',
  '3': 'G3',
  '4': 'G4',
  '5': 'G5',
  '6': 'G6',
  '7': 'G7',
  '8': 'G8',
  '9': 'G9',
  '10': 'G10',
  '11': 'G11',
  '12': 'G12',
};
export type GradeMappingType = '-1' | '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
export const LIMIT = 10;
export const initialTableFilterProps = { limit: LIMIT, page: 1, search: '' };

export const PermissionsMapping: Record<string, PermissionsType[]> = {
  read: ['read'],
  write: ['read', 'write'],
  delete: ['read', 'write', 'delete'],
  'delete-write': ['delete'],
  'delete-read': ['write', 'delete'],
  'write-read': ['write'],
};

export const weekDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
export const weekDaysFullNames = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const dayMapping = {
  mon: 'monday',
  tue: 'tuesday',
  wed: 'wednesday',
  thu: 'thursday',
  fri: 'friday',
  sat: 'saturday',
};
export const CHUNK_SIZE = 5 * 1024 * 1024; // 5 MB

// @TODO: Need to add correct data and translation
export const GeneralInformationData = [
  {
    name: 'Arts, Sports, Activities (ASA) Information',
    descriptions: [
      'We are excited to roll out some changes to the ASA programs for this year.  Here’s what is new or notable.',
    ],
    list: [
      {
        point: 'This year, there are  three seasons. ',
        subPoints: [
          'Season 1: August 12-October 26',
          'Season 2: November 4-February 15',
          'Season 3: February 24-May 24 ',
        ],
      },
      {
        point:
          'The first two ASAs for a child are free (except for material costs for some classes). Additional ASAs will cost 6,000 INR, plus any additional fees.',
      },
      {
        point:
          'Parents will be able to register for ASAs right in RaptorNet, and even make changes through the first week.',
      },
      {
        point: 'After the first week, payments for ASAs can be made in RaptorNet.',
      },
      {
        point: 'Registration will open for Season 1 on August 6 at 6:00 p.m. and close on August 10 at 10:00 a.m.',
      },
      {
        point:
          'Registration spots will be first-come-first served. You will be able to see available slots, and join waitlists in RaptorNet.',
      },
      {
        point:
          'For swim lesson registration, it is important to know what level a child is. To know the levels for returning students, please reach out to dmonicka@aischennai.org.',
      },
    ],
  },
  {
    name: 'Changing of Seasons',
    descriptions: [
      'We are switching to three seasons as our HS and MS sports will all also follow three seasons.  Switching our ASAs will make facility use and staff availability align for all extracurricular activities. The longer seasons will also allow for ASAs to further develop student skills. It also changes the registration dates and season starts so they do not line up with school breaks, allowing for a more seamless transition back to routines after a break.',
      'The increase in fees for additional ASAs (beyond the two free) is due to each season being a few weeks longer than previous years. However the overall cost for a family that paid for at least one ASA every season will have gone down for the year. (Example: 2 extra ASAs every season was a total of 40,000 INR last year. This year it would be 36,000 INR.) ',
    ],
  },
  {
    name: 'Student Transition',
    descriptions: [
      'Teachers will accompany children to ES Cafeteria at 3:30 p.m. (Mon, Tue, Thu, Fri) and 2:30 p.m. on Wednesdays. Students can bring a snack that can be eaten quickly before the ASA starts. The ES Cafeteria is closed to parents from 3:30–3:45 p.m. (Mon, Tue, Thu, Fri) and 2:30–2:45 p.m. on Wednesdays. Please avoid entering the ES Cafeteria during these times for smooth student dismissal. The Madras Cafe is open for parents to wait.',
      'Middle School Students: Go directly to assigned facilities.',
    ],
  },
  {
    name: 'Student Dispersal',
    descriptions: [
      'Elementary Parents can pick up children by 4:45 p.m. (Mon, Tue, Thu, Fri) and 3:45 p.m. on Wednesdays from ES Cafeteria; handover at the Elementary gate for drivers/nannies. Please keep us informed for gate pickups. Please be on time so that instructors can leave on time as well. Authorized Pickup: Only listed parents/guardians or authorized persons will be allowed to pick up children.',
      'School Bus Users: Chaperones will be at the ES Cafeteria for pickup.',
    ],
  },
  {
    name: 'Absences/Early Pickup',
    descriptions: ['Please email dmonicka@aischennai.org.'],
  },
];

export const SchoolLevelImagesData: Record<string, string> = {
  3: '/images/elementary.png',
  4: '/images/middleSchool.png',
  5: '/images/highSchool.png',
};

export const PlaceOrderStatusImagesPath = {
  PAID: '/images/tick-icon.png',
  FAILED: '/images/pending-icon.png',
};

export const GstTypeOptions = [
  { label: 'Local', value: 'local' },
  { label: 'International', value: 'international' },
];
