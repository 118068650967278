import { useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounce = (fn: (this: any, ...args: any[]) => void, ms = 300) => {
  const timeoutId = useRef<ReturnType<typeof setTimeout>>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => fn.apply(this, args), ms);
  };
};

export default useDebounce;
