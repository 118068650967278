import { getFullName } from '@src/lib/helper';
import { FilterOptions } from '@src/lib/types';
import { Staff } from '@src/Models/staff';
import { ResponseData } from '@src/screens/Staff/type';

export const filterOptions = (
  staffList: ResponseData<Staff> | undefined,
  homeRoomStaffList: ResponseData<Staff> | undefined
): FilterOptions[] => [
  {
    label: 'assignedTo',
    key: 'assigned_to',
    value: (staffList?.data || []).map(({ id, first_name, last_name }) => ({
      value: id,
      label: getFullName(first_name, last_name),
    })),
  },
  {
    label: 'homeroomTeacher',
    key: 'home_room',
    value: (homeRoomStaffList?.data || []).map(({ id, first_name, last_name }) => ({
      value: id,
      label: getFullName(first_name, last_name),
    })),
  },
];
