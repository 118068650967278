import axios from 'axios';
import { t } from 'i18next';
import moment, { Moment } from 'moment';

import { refreshAccessToken } from '@src/api/axios';
import { SelectOptionType } from '@src/components/FormController/type';
import { HeadersType } from '@src/contexts/AuthContextProvider';
import { PermissionsType, RolesAllowedTo, RolesPermission, SubModuleProps } from '@src/Models/modules';
import { AllowedTo, Roles } from '@src/Models/roles';
import { User } from '@src/Models/user';
import { ResponseData } from '@src/screens/Staff/type';
import { deleteCookies } from '@src/utils/deleteCookies';
import { getCookie } from '@src/utils/getCookie';

import { SelectNameId } from './styles/types/GlobalTypes';
import { AsaSubRoutes, DrawerList, HeaderList, PermissionsMapping, RouteActions, Routes } from './constants';
import { GetRoutesConfigType, LocationType, permissionEnum, RoutesConfigType } from './types';

export const checkPermissions = (permission: permissionEnum): boolean => {
  console.log('🚀 ~ checkPermissions ~ permission:', permission);
  return true;
};

export const isAuthenticated = () => {
  const hasAuth = !!getCookie('access_token') || !!getCookie('refresh_token');

  if (!hasAuth) {
    deleteCookies(['access_token', 'refresh_token', 'remember_me']);
    localStorage.removeItem('mode');
  }

  return hasAuth;
};

export const getTranslatedValidation = (
  text: string,
  type: 'required' | 'number' | 'positive' | 'min' | 'none' | 'invalidEmail' = 'required',
  value: number = 0
) => {
  const translatedText = t(text) + ' ';

  switch (type) {
    case 'invalidEmail':
      return t('invalidEmail');

    case 'number':
      return translatedText + t('isNumber');

    case 'positive':
      return translatedText + t('isPositive');

    case 'min':
      return translatedText + t('minValidation').replace('####', String(value));
    case 'none':
      return translatedText;
    default:
      return translatedText + t('isRequired');
  }
};

export function convertDateFormat(dateString: string): string {
  if (!dateString) return '';

  const parts = dateString.split('-');

  if (parts.length !== 3) {
    return '';
  }

  const month = parseInt(parts[0]);
  const day = parseInt(parts[1]);
  const year = parseInt(parts[2]);

  if (isNaN(month) || isNaN(day) || isNaN(year)) {
    return '';
  }

  const isoDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
  return isoDate;
}

export const dateComparison = (dateOne: string, dateTwo: string) => {
  return moment(dateOne).isBefore(moment(dateTwo));
};

export const currentDateComparison = (date: string) => {
  return moment().isAfter(moment(date));
};

export const dateBwtComparison = (dateOne: string, dateTwo: string) => {
  const startDate = moment(dateOne);
  const endDate = moment(dateTwo);
  const currentDate = moment();

  return currentDate.isBetween(startDate, endDate, undefined, '[]');
};

export const timeComparison = (timeOne: string, timeTwo: string) => {
  const format = 'HH:mm';
  const timeOneMoment = moment(timeOne, format);
  const timeTwoMoment = moment(timeTwo, format);

  return timeOneMoment.isSameOrBefore(timeTwoMoment);
};

export const getCountriesStatesCities = async (country?: string, state?: string) => {
  const headers = {
    'X-CSCAPI-KEY': process.env.REACT_APP_COUNTRY_API_KEY,
  };

  let urlExtension = '';

  if (country && state) urlExtension += `/${country}/states/${state}/cities`;
  else if (country) urlExtension += `/${country}/states`;

  const url = 'https://api.countrystatecity.in/v1/countries' + urlExtension;

  try {
    const response = await axios.get(url, { headers });

    if (response.status !== 200) {
      console.error('Error fetching countries');
      return [];
    }

    const sortedData = (response.data as LocationType[]).sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return sortedData.map(values => ({
      label: values.name,
      value: values.iso2 || values.id,
    }));
  } catch (error) {
    console.error('Error fetching countries:', error);
  }
};

export const getCountriesCodes = async () => {
  const headers = {
    'X-CSCAPI-KEY': process.env.REACT_APP_COUNTRY_API_KEY,
  };

  const url = 'https://api.countrystatecity.in/v1/countries';

  try {
    const response = await axios.get(url, { headers });

    if (response.status !== 200) {
      console.error('Error fetching countries');
      return [];
    }

    const data = response.data as LocationType[];

    return data
      .filter(item => item.phonecode)
      .map(values => ({
        label: `${values.emoji} (${values.phonecode.startsWith('+') ? '' : '+'}${values.phonecode})`,
        value: values.phonecode,
      }));
  } catch (error) {
    console.error('Error fetching countries:', error);
  }
};

type getPermissionType = (permissions: PermissionsType[]) => PermissionsType | null;

const getPermission: getPermissionType = permissions => {
  if (permissions.includes('delete')) return 'delete';

  if (permissions.includes('write')) return 'write';

  if (permissions.includes('read')) return 'read';

  return null;
};

const convertToCamelCase = (input: string): string => {
  const exceptions: Record<string, string> = { ASA: 'asa', 'Purchase Order': 'po' };

  if (exceptions[input]) return exceptions[input];

  return input
    .split(/(?=[A-Z])|\s+/)
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    })
    .join('');
};

type getHeadersWithPermissionsType = (
  permissions: Roles,
  additional_permissions?: Roles
) => Record<string, Record<string, string>>;

export const getHeadersWithPermissions: getHeadersWithPermissionsType = (permissions, additional_permissions) => {
  const headers: Record<string, Record<string, string>> = {};

  const allowedTo = [
    ...(additional_permissions ? Object.entries(additional_permissions?.allowed_to) : []),
    ...Object.entries(permissions.allowed_to),
  ];

  for (const [moduleKey, modules] of allowedTo) {
    const subModulePermission: Record<string, string> = {};

    const additionalSubModules = additional_permissions?.allowed_to?.[moduleKey];

    const subModules = [
      ...(additionalSubModules ? Object.entries(additionalSubModules.sub_modules) : []),
      ...Object.entries(modules.sub_modules),
    ];

    for (const [subModuleKey, subModule] of subModules) {
      const additionalPermission = additionalSubModules?.sub_modules?.[subModuleKey];
      const permission = getPermission((additionalPermission || subModule).permissions);

      if (permission)
        subModulePermission[subModule.sub_module_name.toLocaleLowerCase().replaceAll(' ', '-')] = permission;
    }

    headers[convertToCamelCase(modules.module_name)] = subModulePermission;
  }

  return headers;
};

export function formatDate(dateString: string, format?: Intl.DateTimeFormatOptions): string {
  if (!dateString) {
    return '-';
  }

  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    ...format,
  };

  let formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);

  const monthAbbreviations = {
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'May',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sept: 'Sept',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dec',
  };

  for (const [key, value] of Object.entries(monthAbbreviations)) {
    formattedDate = formattedDate.replace(key, value);
  }

  return formattedDate;
}

export function capitalizeFirstLetter(text: string) {
  if (!text) return text;

  const textArr = text.trim().split('_');

  return textArr.reduce((acc, curr) => {
    return (acc ? acc + ' ' : '') + curr.charAt(0).toUpperCase() + curr.toLowerCase().slice(1);
  }, '');
}

export const formatSelectValue = (values: SelectNameId[] = []): SelectOptionType[] => {
  return values.map(({ name, id }) => ({ label: name, value: id }));
};

export const getFileType = (fileName: string): string => {
  const splitFileName = fileName.split('.');
  return splitFileName.length > 1 ? splitFileName.pop()!.toLowerCase() : '';
};

export const formatCostToINR = (cost: string | number) => {
  if (!cost) return '₹0.00';

  const [integralPart, decimalPart] = `${cost}`?.split('.');

  const formattedIntegralPart = integralPart.replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');

  return decimalPart ? `₹${formattedIntegralPart}.${decimalPart}` : `₹${formattedIntegralPart}.00`;
};

export const getRolePermisions = ({
  currentSelectedModule,
  permission,
  permissionsData,
  subModuleId,
  existingPermissionsData,
}: {
  subModuleId: string;
  permission: PermissionsType;
  permissionsData?: RolesPermission;
  currentSelectedModule: string;
  existingPermissionsData?: RolesPermission;
}) => {
  const permissionType = getExistingLastPermission({
    existingPermissionsData,
    currentSelectedModule,
    subModuleId,
    permission,
  });

  const newPermissions = PermissionsMapping[permissionType];

  const updatedAllowedTo = (permissionsData?.allowed_to || []).map(data => {
    if (data?.module_id === currentSelectedModule) {
      const updatedSubModules = (data?.sub_modules || []).map(subModule => {
        if (subModuleId === subModule.sub_module_id) {
          let updatedPermissions: PermissionsType[] = [];

          // here arrays are compared by reference
          if (subModule.permissions !== newPermissions) {
            updatedPermissions = newPermissions;
          }

          return { ...subModule, permissions: updatedPermissions };
        }

        return subModule;
      });

      if (!updatedSubModules.some(({ sub_module_id }) => sub_module_id === subModuleId)) {
        updatedSubModules.push({ sub_module_id: subModuleId, permissions: newPermissions });
      }

      return { ...data, sub_modules: updatedSubModules };
    }

    return data;
  });

  const moduleExists = updatedAllowedTo.some(({ module_id }) => module_id === currentSelectedModule);

  if (!moduleExists) {
    updatedAllowedTo.push({
      module_id: currentSelectedModule,
      sub_modules: [{ sub_module_id: subModuleId, permissions: newPermissions }],
    });
  }

  return {
    allowed_to: updatedAllowedTo.reduce((acc: RolesAllowedTo[], data) => {
      const filteredSubModules = data.sub_modules.filter(({ permissions }) => permissions.length);

      if (filteredSubModules.length) {
        acc.push({ ...data, sub_modules: filteredSubModules });
      }

      return acc;
    }, []),
  };
};

const getExistingLastPermission = ({
  existingPermissionsData,
  currentSelectedModule,
  subModuleId,
  permission,
}: {
  existingPermissionsData?: RolesPermission;
  currentSelectedModule: string;
  subModuleId: string;
  permission: PermissionsType;
}) => {
  if (!existingPermissionsData) return permission;

  const module = existingPermissionsData.allowed_to.find(item => item.module_id === currentSelectedModule);

  if (!module) return permission;

  const subModule = module.sub_modules.find(item => item.sub_module_id === subModuleId);

  if (!subModule) return permission;

  return permission + '-' + subModule.permissions[subModule.permissions.length - 1];
};

export const getUpdatedRolePermisions = ({
  currentSelectedModule,
  permission,
  permissionsData,
  subModuleId,
  existingPermissionsData,
}: {
  subModuleId: string;
  permission: PermissionsType;
  permissionsData?: RolesPermission;
  existingPermissionsData?: RolesPermission;
  currentSelectedModule: string;
}) => {
  const permissionType = getExistingLastPermission({
    existingPermissionsData,
    currentSelectedModule,
    subModuleId,
    permission,
  });

  const newPermissions = PermissionsMapping[permissionType];

  const updatedAllowedTo = (permissionsData?.allowed_to || []).map(data => {
    if (data.module_id === currentSelectedModule) {
      const updatedSubModules = (data.sub_modules || []).map(subModule => {
        if (subModule.sub_module_id === subModuleId) {
          let updatedPermissions: PermissionsType[] = [];

          // here arrays are compared by reference
          if (subModule.permissions !== newPermissions) {
            updatedPermissions = newPermissions;
          }

          return { ...subModule, permissions: updatedPermissions };
        }

        return subModule;
      });

      if (!updatedSubModules.some(({ sub_module_id }) => sub_module_id === subModuleId)) {
        updatedSubModules.push({ sub_module_id: subModuleId, permissions: newPermissions });
      }

      return { ...data, sub_modules: updatedSubModules };
    }

    return data;
  });

  const moduleExists = updatedAllowedTo.some(({ module_id }) => module_id === currentSelectedModule);

  if (!moduleExists) {
    updatedAllowedTo.push({
      module_id: currentSelectedModule,
      sub_modules: [{ sub_module_id: subModuleId, permissions: newPermissions }],
    });
  }

  return { allowed_to: updatedAllowedTo };
};

export const getFullName = (firstName?: string, lastName?: string) => {
  if (!firstName) {
    return '--';
  }

  return firstName + ' ' + (lastName ?? '');
};

export const gradeLevelMapping: Record<string, string> = {
  '-1': 'EY',
  '0': 'KG',
  '1': 'G1',
  '2': 'G2',
  '3': 'G3',
  '4': 'G4',
  '5': 'G5',
  '6': 'G6',
  '7': 'G7',
  '8': 'G8',
  '9': 'G9',
  '10': 'G10',
  '11': 'G11',
  '12': 'G12',
};
export function iterateDates(startDate: string, endDate: string, callback: (date: Moment) => void): void {
  const start = moment(startDate);
  const end = moment(endDate);

  if (start.isAfter(end)) {
    return;
  }

  for (let current = start.clone(); !current.isAfter(end); current.add(1, 'days')) {
    callback(current.clone());
  }
}

export function dateFormatter(date: Date) {
  return moment(date).format('MMMM Do YYYY');
}

export const getPermittedRoute = (headers: HeadersType, userDetail?: User) => {
  let value = Routes.ASA + AsaSubRoutes.PROGRAMS;

  if (!userDetail || !headers || headers === 'super_admin') return value;

  if (['parent', 'student', 'staff_cum_parent'].includes(userDetail?.role || '')) return Routes.DASHBOARD;

  for (const drawerItem of DrawerList) {
    const headerList = HeaderList({ headers, selectedModule: drawerItem.text });

    if (!headerList) continue;

    value = `/${drawerItem.text}/${headerList[0]}`;
    break;
  }

  return value;
};

export function getRandomColor(): string {
  const letters = '0123456789ABCDEF';
  let color = '#';

  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
}

function hashCode(str: string): number {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
}

function intToRGB(i: number): string {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
}

export function generateUniqueColors(uuids: string[]): string[] {
  const colors: string[] = uuids.map(uuid => {
    const hash = hashCode(uuid);
    return `#${intToRGB(hash)}`;
  });

  return colors;
}

export function convert24HourTo12Hour(time24: string): string {
  const timeMoment = moment(time24, 'HH:mm');

  return timeMoment.format('hh:mm A');
}

export const appendUniqueRecords = <T extends { id: string }>(
  prev: ResponseData<T> | undefined,
  curr: ResponseData<T>
): ResponseData<T> => {
  const oldData = prev?.data ?? [];
  const newData = curr?.data ?? [];
  const combinedData = [...oldData, ...newData];

  const seenIds = new Set();

  const uniqueData = combinedData.filter(item => {
    if (seenIds.has(item.id)) {
      return false;
    } else {
      seenIds.add(item.id);
      return true;
    }
  });

  return {
    current_page: curr.current_page,
    data: uniqueData,
    total: curr.total,
  };
};

export const uniqueByLabelAndValue = (arr: SelectOptionType[]) => {
  const seen = new Set();
  return arr.filter(item => {
    const identifier = `${item.label}|${item.value}`;

    if (seen.has(identifier)) {
      return false;
    } else {
      seen.add(identifier);
      return true;
    }
  });
};

export const transformData = (data: AllowedTo): RolesPermission => {
  const allowedToArray: RolesAllowedTo[] = [];

  for (const [moduleId, moduleData] of Object.entries(data)) {
    const subModulesArray: SubModuleProps[] = [];

    for (const [subModuleId, subModuleData] of Object.entries(moduleData?.sub_modules)) {
      subModulesArray.push({
        sub_module_id: subModuleId,
        permissions: subModuleData.permissions,
      });
    }

    allowedToArray.push({
      module_id: moduleId,
      sub_modules: subModulesArray,
    });
  }

  return {
    allowed_to: allowedToArray,
  };
};

export async function fetchAccessToken() {
  try {
    await refreshAccessToken();
    return true;
  } catch (refreshError) {
    console.error('Error while fetching access token using refresh token:', refreshError);
    return false;
  }
}

export function downloadFromBlob(name: string, blob: Blob) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${name}.xlsx`;
  document.body.appendChild(a);
  a.click();

  // Clean up
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}

export const getRoutesConfig: GetRoutesConfigType = data => {
  const RoutesConfig: RoutesConfigType[] = [];

  for (const { path, listing, createEdit, view } of data) {
    if (listing) {
      RoutesConfig.push({
        path,
        component: listing,
        layout: 'private',
      });
    }

    if (view) {
      RoutesConfig.push({
        path: path + '/:id',
        component: view,
        layout: 'private',
      });
    }

    if (createEdit) {
      RoutesConfig.push({
        path: path + RouteActions.CREATE,
        component: createEdit,
        layout: 'private',
      });

      RoutesConfig.push({
        path: path + RouteActions.EDIT + '/:id',
        component: createEdit,
        layout: 'private',
      });
    }
  }

  return RoutesConfig;
};
