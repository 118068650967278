import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import HomeRoomAssignModal from '@src/components/Modals/HomeRoomAssignModal';
import Table from '@src/components/Table';
import { handleFetchDataType } from '@src/components/Table/type';
import { useAxiosApi } from '@src/hooks/useAxiosApi';
import { initialTableFilterProps, Routes, UsersSubRoutes } from '@src/lib/constants';
import { appendUniqueRecords } from '@src/lib/helper';
import { StyledTableWrapper } from '@src/lib/styles/globalWrapper';
import { Staff } from '@src/Models/staff';

import { filterOptions } from './filterOptions';
import { tableHeaders } from './tableHeaders';
import { ResponseData } from './type';

const HomeroomTeacher: React.FC = () => {
  const { handleGetHomeroomTeachers, handleDeleteAssignHomeRoomTeacher, handleGetStaffs } = useAxiosApi();
  const navigate = useNavigate();
  const refetch = useRef<() => void>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [staffList, setStaffList] = useState<ResponseData<Staff>>();
  const [homeRoomStaffList, setHomeRoomStaffList] = useState<ResponseData<Staff>>();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const fetchStaffList = async (currentPage = 1) => {
    try {
      const resDept = await handleGetStaffs({
        data: { ...initialTableFilterProps, page: currentPage, mapping: true },
        isLoading: 'table',
      });

      setStaffList(prev => appendUniqueRecords(prev, resDept));
    } catch (error) {
      console.error('Error fetching staff list:', error);
    }
  };

  const fetchHomeRoomStaffList = async (currentPage = 1) => {
    try {
      const resDept = await handleGetStaffs({
        data: { ...initialTableFilterProps, page: currentPage, only_homeroom: true },
        isLoading: 'table',
      });

      setHomeRoomStaffList(prev => appendUniqueRecords(prev, resDept));
    } catch (error) {
      console.error('Error fetching staff list:', error);
    }
  };

  const handleScrollToBottom = (selectedFilterModule: string) => {
    switch (selectedFilterModule) {
      case 'assignedTo':
        if (staffList && staffList?.data?.length < staffList?.total) {
          fetchStaffList(staffList?.current_page + 1);
        }

        break;

      case 'homeroomTeacher':
        if (homeRoomStaffList && homeRoomStaffList?.data?.length < homeRoomStaffList?.total) {
          fetchHomeRoomStaffList(homeRoomStaffList?.current_page + 1);
        }

        break;

      default:
        console.warn(`Unknown filter module: ${selectedFilterModule}`);
        break;
    }
  };

  const handleFetchData: handleFetchDataType = async ({ rowsPerPage, page, searchText = '', filters, sortBy }) => {
    try {
      const response = await handleGetHomeroomTeachers({
        data: { limit: rowsPerPage, page, search: searchText, ...filters, ...sortBy },
        isLoading: 'table',
      });

      return {
        data:
          response?.data?.map(item => ({
            id: item.id,
            home_room: item.home_room,
            assigned_by: item.assigned_by ? item.assigned_by.username : '-',
            assigned_to: item.assigned_to
              ? item.assigned_to.first_name + ' ' + (item.assigned_to.last_name || '')
              : '-',
            due_date: item.due_date || 'No Due Date',
          })) || [],
        count: response?.total || 0,
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      return { data: [], count: 0 };
    }
  };

  const handleTableRowClick = (id: string) => {
    navigate(`${Routes.USERS}${UsersSubRoutes.MANAGE_HOME_ROOM}/${id}`);
  };

  const handleDelete = async (id: string) => {
    if (!id) return;

    try {
      await handleDeleteAssignHomeRoomTeacher(id);
    } catch (error) {
      console.log('Error', error);
    }
  };

  const handleSubmit = () => {
    refetch.current?.();
  };

  useEffect(() => {
    fetchStaffList();
    fetchHomeRoomStaffList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledTableWrapper>
      <Table
        headers={tableHeaders}
        handleFetchData={handleFetchData}
        handleTableRowClick={handleTableRowClick}
        title='homeroom'
        addRowData={{
          buttonText: 'assign',
          handleAddClick: handleOpenModal,
        }}
        tableActions={{
          handleDelete,
          deleteMessage: 'homeroom',
        }}
        getRefetch={instance => {
          refetch.current = instance;
        }}
        filterSchema={{
          filterOptions: filterOptions(staffList, homeRoomStaffList),
          handleScrollToBottom,
        }}
      />
      <HomeRoomAssignModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} onSubmit={handleSubmit} />
    </StyledTableWrapper>
  );
};

export default HomeroomTeacher;
