import apiEndpoints from '@src/lib/apiEndpoints';
import { FormControllerDataReturn } from '@src/lib/types';
import { PlaceOrdersReturnProps } from '@src/Models/merchandiseOrders';
import { ProductCategory } from '@src/Models/ProductCategory';
import { ResponseData } from '@src/screens/Staff/type';

import apiRequest from './api';
import { GetTableFilterProps } from './type';

export const getProductCategories = (params: GetTableFilterProps): Promise<ResponseData<ProductCategory>> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.MERCHANDISE_CATEGORY}`,
    config: { params },
  });
};

export const createProductCategory = async (data: FormControllerDataReturn) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.MERCHANDISE_CATEGORY}`,
    data: data,
  });
};

export const updateProductCategory = async (id: string, data: FormControllerDataReturn) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.MERCHANDISE_CATEGORY}/${id}`,
    data: data,
  });
};

export const deleteProductCategory = async (id: string) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.MERCHANDISE_CATEGORY}/${id}`,
  });
};

export const getProductCategoryById = async (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.MERCHANDISE_CATEGORY}/${id}`,
  });
};

export const deleteProductVariant = async (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.MERCHANDISE_PRODUCT_DISABLE_VARIANT}/${id}`,
  });
};

export const updateProductDetails = async (id: string, data: FormControllerDataReturn) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.MERCHANDISE_PRODUCT}/${id}`,
    data: data,
  });
};

export const updateProductVariantDetails = async (id: string, data: FormControllerDataReturn) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.MERCHANDISE_UPDATE_VARIANT}/${id}`,
    data: data,
  });
};

export const addNewProductVariant = async (id: string, data: FormControllerDataReturn) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.MERCHANDISE_ADD_VARIANT}/${id}`,
    data: data,
  });
};

export const placeProductOrder = async (data: FormControllerDataReturn) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.PLACE_ORDER}/`,
    data: data,
  });
};

export const getOrder = async (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.MERCHANDISE_ORDER}/${id}`,
  });
};

export const editOrder = (id: string, data: FormControllerDataReturn) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.MERCHANDISE_ORDER_STATUS}/${id}`,
    data,
  });
};

export const getProductVariantById = async (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.PRODUCT_VARIANT}/${id}`,
  });
};

export const getMerchandiseOrders = (params: GetTableFilterProps): Promise<ResponseData<PlaceOrdersReturnProps>> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.MERCHANDISE_ORDERS}`,
    config: { params },
  });
};
