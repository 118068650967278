import { useTranslation } from 'react-i18next';

import { Download } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import { TableDownloadSchemaLayoutProps } from '../type';

const TableDownloadSchemaLayout = ({ downloadSchemaLinks }: TableDownloadSchemaLayoutProps) => {
  const { t } = useTranslation();

  const downloadFile = (url: string, filename: string) => {
    const link = document.createElement('a');
    link.href = `${process.env.REACT_APP_API_S3_URL}${url}`;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownload = () => {
    downloadSchemaLinks?.forEach((url, index) => {
      const filename = url.split('/').pop();
      setTimeout(() => {
        downloadFile(url, filename as string);
      }, index * 1000);
    });
  };

  return (
    <Tooltip title={t('download')}>
      <IconButton onClick={handleDownload}>
        <Download sx={{ color: 'inherit' }} />
      </IconButton>
    </Tooltip>
  );
};

export default TableDownloadSchemaLayout;
