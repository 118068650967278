import { useTranslation } from 'react-i18next';

import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import FormController from '@src/components/FormController';

import { BootstrapDialog } from '../style';
import { GenericModalProps } from '../type';

export const GenericFormModal = ({
  fields,
  handleClose,
  isOpen,
  onSubmit,
  validationSchema,
  heading,
  customSubmitSubmission,
  backButton,
  children,
  getFormController,
  submitButton,
  submitButtonFullWidth,
  values,
  maxWidth = 'sm',
  childrenBeforeForm,
}: GenericModalProps) => {
  const { t } = useTranslation();

  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby='form-modal-title' open={isOpen} maxWidth={maxWidth}>
      {heading && (
        <DialogTitle sx={{ m: 0, p: 2 }} id='form-modal-title'>
          <h3>{t(heading)}</h3>
        </DialogTitle>
      )}
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers style={{ display: 'flex', flexDirection: 'column', padding: 25 }}>
        {childrenBeforeForm}
        <FormController
          values={values}
          fields={fields}
          onSubmit={onSubmit}
          customSubmitSubmission={customSubmitSubmission}
          submitButtonFullWidth={!!submitButtonFullWidth}
          validationSchema={validationSchema}
          backButton={backButton}
          getFormController={getFormController}
          submitButton={submitButton}
        >
          {children}
        </FormController>
      </DialogContent>
    </BootstrapDialog>
  );
};
