import styled from 'styled-components';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { Divider, styled as muiStyled } from '@mui/material';

export const StyledDetailHelmet = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.inkShade};
`;

export const gridContainerStyle = {
  spacing: { xs: 2, md: 3 },
  columns: { xs: 4, sm: 8, md: 12 },
};

export const gridItemStyle = {
  xs: 2,
  sm: 4,
  md: 3,
};

export const StyledLabel = styled.h4`
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.inkShade};
`;

export const StyledTextValue = styled.div<{ $isEmail: boolean }>`
  text-transform: ${({ $isEmail }) => ($isEmail ? '' : 'capitalize')};
  word-break: break-all;
  color: ${({ theme }) => theme.colors.inkShade};
`;

export const StyledEditIcon = styled(EditIcon)`
  color: ${({ theme }) => theme.colors.inkShade};
`;
export const StyledArrowBackIcon = styled(ArrowBackIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.inkShade};
`;

export const StyledDivider = muiStyled(Divider)<{ $space?: number }>`
  margin: ${({ theme, $space }) => theme.spacing($space || 2)} 0;
`;

export const StyledHeading = styled.h2`
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    padding-right: ${({ theme }) => theme.space.get('2xs')};
  }
`;
