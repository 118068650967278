import apiEndpoints from '@src/lib/apiEndpoints';

import apiRequest from './api';

export interface CampPaymentsXcelUploadProps {
  camp_name: string;
}

export const campPaymentsXcelUpload = async (file: FormData, params: CampPaymentsXcelUploadProps) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.CAMP_REGISTRATIONS}`,
    data: file,
    contentType: 'multipart/form-data',
    config: { params },
  });
};
