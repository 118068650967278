import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import DetailsCard from '@src/components/DetailsCard';
import { useAxiosApi } from '@src/hooks/useAxiosApi';
import { StyledDetailsCardWrapper } from '@src/lib/styles/globalWrapper';
import { HomeroomTeacherType } from '@src/Models/homeromTeacher';

import { homeRoomDetails } from './formSchema';

const HomeRoomDetailView: React.FC = () => {
  const [homeRoomDetail, setHomeRoomDetail] = useState<HomeroomTeacherType>();

  const { handleHomeroomTeacherById } = useAxiosApi();
  const { id } = useParams<{ id: string }>();

  const fetchHomeRoomByID = async () => {
    if (!id) return;

    try {
      const res = await handleHomeroomTeacherById(id);

      setHomeRoomDetail(res.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    fetchHomeRoomByID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledDetailsCardWrapper $display='flex'>
      <DetailsCard details={homeRoomDetails(homeRoomDetail)} heading='HomeRoomTeacher' />
    </StyledDetailsCardWrapper>
  );
};

export default HomeRoomDetailView;
