/* eslint-disable complexity */
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { MuiChipsInput } from 'mui-chips-input';

import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';

import useDebounce from '@src/hooks/useDebounce';
import { useGetTheme } from '@src/hooks/useGetTheme';
import { uniqueByLabelAndValue } from '@src/lib/helper';

import AttachFiles from '../attachments';
import { MiddleTextLine } from '../MiddleText';

import PhoneNumberInput from './PhoneNumberInput';
import {
  customStyles,
  StyledAddButton,
  StyledAddButtonContainer,
  StyledAddText,
  StyledErrorMessage,
  StyledLabel,
  StyledSelectContainer,
  StyledTextField,
} from './style';
import { FieldLayoutProps } from './type';

export const FieldLayout = ({
  label,
  name,
  type = 'text',
  gutter = { md: 12, sm: 8, xs: 4 },
  isEditable = true,
  initialValue,
  options = [],
  isMulti,
  required = false,
  onChange,
  attachmentOptions,
  onAddOption,
  placeholder,
  handleScrollToBottom,
  handleSearchText,
  formController,
}: FieldLayoutProps) => {
  const { t } = useTranslation();
  const { styledTheme } = useGetTheme();

  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const debouncedHandleSearchText = useDebounce(searchText => {
    if (!handleSearchText) return;

    setLoading(true);
    handleSearchText(searchText).finally(() => {
      setLoading(false);
    });
  }, 500);

  const {
    control,
    setValue,
    formState: { errors },
  } = formController;

  return (
    <Grid item {...gutter} key={name}>
      <Controller
        key={name}
        name={name}
        control={control}
        defaultValue={initialValue || null}
        render={({ field: controllerField }) => {
          if (type === 'chipInput')
            return (
              <MuiChipsInput
                {...controllerField}
                label={t(label)}
                value={controllerField.value || []}
                onAddChip={chip => setValue(name, [...(controllerField.value || []), chip], { shouldDirty: true })}
                onDeleteChip={(_, index) => {
                  const newValue = [...(controllerField.value || [])];
                  newValue.splice(index, 1);
                  setValue(name, newValue, { shouldDirty: true });
                }}
                fullWidth
                variant='outlined'
                size='small'
                helperText={errors[name]?.message?.toString() || ''}
                error={!!errors[name]}
              />
            );

          if (type === 'otp')
            return (
              <Box>
                <MiddleTextLine text={t('authenticationCode')} />
                <StyledTextField
                  {...controllerField}
                  type='text'
                  hiddenLabel
                  variant='outlined'
                  size='small'
                  fullWidth
                  required={required}
                  InputLabelProps={{ shrink: !!controllerField.value }}
                  helperText={errors[name]?.message?.toString() || ''}
                  onInput={e => {
                    const target = e.target as HTMLInputElement;
                    const newValue = target.value.replace(/\D/g, '');
                    target.value = newValue.slice(0, 6);
                    setValue(name, target.value, { shouldDirty: true });
                  }}
                  placeholder='xxxxxx'
                />
              </Box>
            );

          if (type === 'recoveryCode')
            return (
              <Box>
                <MiddleTextLine text='Recovery Code' />
                <StyledTextField
                  {...controllerField}
                  type='text'
                  hiddenLabel
                  variant='outlined'
                  size='small'
                  fullWidth
                  required={required}
                  InputLabelProps={{ shrink: !!controllerField.value }}
                  helperText={errors[name]?.message?.toString() || ''}
                  onInput={e => {
                    const target = e.target as HTMLInputElement;
                    target.value = target.value.slice(0, 10);
                    setValue(name, target.value, { shouldDirty: true });
                  }}
                  placeholder='xxxxx-xxxxx'
                />
              </Box>
            );

          if (type === 'checkbox')
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    {...controllerField}
                    required={required}
                    checked={!!controllerField.value}
                    onChange={e => {
                      setValue(name, e.target.checked, { shouldDirty: true });
                      onChange?.({ label: name, value: e.target.checked ? 'true' : '' });
                    }}
                    color='primary'
                  />
                }
                label={t(label)}
                disabled={!isEditable}
              />
            );

          if (type === 'select')
            return (
              <StyledSelectContainer>
                <StyledLabel htmlFor={name} error={!!errors[name]}>
                  {t(label) + (required ? ' *' : '')}
                </StyledLabel>
                <Select
                  {...controllerField}
                  id={name}
                  options={options || []}
                  isMulti={isMulti}
                  onChange={data => {
                    setValue(name, Array.isArray(data) ? uniqueByLabelAndValue(data) || [] : data, {
                      shouldDirty: true,
                    });
                    onChange?.(Array.isArray(data) ? uniqueByLabelAndValue(data) || [] : data);
                  }}
                  {...(handleSearchText || handleScrollToBottom ? { filterOption: () => true } : {})}
                  menuPortalTarget={document.body}
                  isLoading={loading}
                  styles={customStyles(styledTheme, !!errors[name])}
                  value={controllerField.value || []}
                  inputValue={inputValue}
                  onInputChange={value => {
                    setInputValue(value);
                    debouncedHandleSearchText(value);
                  }}
                  placeholder={placeholder ? t(placeholder) + '...' : undefined}
                  isDisabled={!isEditable}
                  getOptionLabel={option => option.label || ''}
                  getOptionValue={option => option.value}
                  onMenuScrollToBottom={() => {
                    if (!handleScrollToBottom || loading) return;

                    setLoading(true);
                    handleScrollToBottom(inputValue).finally(() => {
                      setLoading(false);
                    });
                  }}
                  noOptionsMessage={({ inputValue: text }) =>
                    onAddOption && (
                      <StyledAddButtonContainer>
                        <StyledAddText style={{ fontSize: '20px' }} id='autoCompleteTextValue' />
                        <StyledAddButton onClick={() => onAddOption(text)}>{text} +</StyledAddButton>
                      </StyledAddButtonContainer>
                    )
                  }
                />
                {errors[name] && <StyledErrorMessage>{errors[name]?.message?.toString() || ''}</StyledErrorMessage>}
              </StyledSelectContainer>
            );

          if (type === 'textarea')
            return (
              <StyledTextField
                {...controllerField}
                label={t(label)}
                disabled={!isEditable}
                error={!!errors[name]}
                helperText={errors[name]?.message?.toString() || ''}
                variant='outlined'
                type={type}
                fullWidth
                multiline
                rows={4}
                required={required}
                size='small'
                {...(!!controllerField.value || ['date', 'time'].includes(type)
                  ? { InputLabelProps: { shrink: true } }
                  : {})}
                value={controllerField.value}
              />
            );

          if (type === 'attachment') {
            return (
              <AttachFiles
                handleUploadFile={attachmentOptions?.handleUploadFile}
                uploadSingle={attachmentOptions?.uploadSingle}
                uploadButtonText={attachmentOptions?.uploadButtonText}
                loading={attachmentOptions?.loading}
                accept={attachmentOptions?.accept}
              />
            );
          }

          if (type === 'tel') {
            return (
              <PhoneNumberInput
                name={name}
                label={label}
                value={controllerField.value || ''}
                onChange={value => {
                  setValue(name, value, { shouldDirty: true });
                  onChange?.({ label: name, value });
                }}
                placeholder={placeholder}
                isEditable={isEditable}
                required={required}
                errorMessage={errors[name]?.message?.toString() || ''}
              />
            );
          }

          return (
            <StyledTextField
              {...controllerField}
              label={t(label)}
              disabled={!isEditable}
              error={!!errors[name]}
              onChange={data => {
                controllerField.onChange(data);
                onChange?.({ label: label, value: data?.target?.value });
              }}
              helperText={errors[name]?.message?.toString() || ''}
              variant='outlined'
              type={type}
              fullWidth
              required={required}
              placeholder={placeholder}
              InputProps={{
                inputProps: {
                  onWheel: event => {
                    event.preventDefault();
                    (event.target as HTMLInputElement).blur();
                  },
                },
              }}
              size='small'
              {...(controllerField.value || ['date', 'time', 'number'].includes(type)
                ? { InputLabelProps: { shrink: controllerField.value !== '' } }
                : {})}
              value={controllerField.value}
            />
          );
        }}
      />
    </Grid>
  );
};
