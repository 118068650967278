import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';

import { gridContainerStyle, gridItemStyle } from './style';

const DetailCardLayout = ({ details }: { details?: { label: string; value?: string }[] }) => {
  const { t } = useTranslation();

  return (
    <Grid container {...gridContainerStyle}>
      {details?.map(({ label, value }, index) => (
        <Grid item {...gridItemStyle} key={`${label}=${index}`}>
          <Typography variant='body2' color='textSecondary'>
            {t(label)}:
          </Typography>
          <Typography variant='body1' fontWeight='bold'>
            {value || '-'}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default DetailCardLayout;
