import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';

import { AlertModal } from '@src/components/Modals/AlertModal';
import { useGetTheme } from '@src/hooks/useGetTheme';

import { ActionMenuProps } from './type';

export const ActionMenu = ({
  id,
  handleDelete,
  handleEdit,
  handleView,
  refetch,
  deleteMessage = '',
  deleteText = '',
  overrideDeleteMessage = '',
}: ActionMenuProps) => {
  const [anchorFilter, setAnchorFilter] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { styledTheme } = useGetTheme();

  const handleCloseFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorFilter(null);
  };

  const handleOpenFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorFilter(event.currentTarget);
  };

  const handleEditAction = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleEdit?.(id);
    handleCloseFilterMenu(event);
  };

  const handleDeleteAction = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    await handleDelete?.(id);
    refetch?.();
    setIsOpen(false);
    handleCloseFilterMenu(event);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(false);
    handleCloseFilterMenu(event);
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(true);
  };

  if (!handleView && !handleEdit && !handleDelete) return <div></div>;

  return (
    <Box>
      <IconButton onClick={handleOpenFilterMenu} sx={{ padding: styledTheme?.space.get('3xs') }}>
        <MoreVert sx={{ color: anchorFilter ? 'skyblue' : 'inherit' }} fontSize='small' />
      </IconButton>
      <Menu
        id='menu-appbar'
        anchorEl={anchorFilter}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorFilter)}
        onClose={handleCloseFilterMenu}
      >
        {handleView ? (
          <MenuItem
            onClick={e => {
              handleView(id);
              handleCloseFilterMenu(e);
            }}
          >
            {t('view')}
          </MenuItem>
        ) : null}
        {handleEdit ? <MenuItem onClick={handleEditAction}>{t('edit')}</MenuItem> : null}
        {handleDelete ? <MenuItem onClick={handleOpen}>{t(deleteText || 'delete')}</MenuItem> : null}
      </Menu>
      <AlertModal
        isOpen={isOpen}
        handleClose={handleClose}
        handleAgree={handleDeleteAction}
        message={`${t(overrideDeleteMessage || 'wantToDelete')} ${t(deleteMessage)}?`}
        heading={overrideDeleteMessage ? t('confirmation') : t('deleteConfirmation')}
      />
    </Box>
  );
};
