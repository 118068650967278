import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Tooltip } from '@mui/material';

import { Field } from '@src/components/FormController/type';
import { GenericFormModal } from '@src/components/Modals/GenericFormModal';

import { StyledButton } from '../style';
import { AddRowHandleProps, AddRowProps, TableCreateRowLayoutProps } from '../type';

export const TableCreateRowLayout: React.FC<TableCreateRowLayoutProps> = ({ addRowData, children }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { handleAddClick, buttonText } = (addRowData as AddRowHandleProps) || {};
  const { fields, onSubmit, validationSchema, heading, maxWidth, onClose } = (addRowData as AddRowProps) || {};

  const handleAddButtonClick = () => {
    handleAddClick ? handleAddClick() : setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose?.();
  };

  return (
    <Box>
      <Tooltip title={t('addRow')}>
        <StyledButton onClick={handleAddButtonClick}>{buttonText ? t(buttonText) : `+ ${t('createNew')}`}</StyledButton>
      </Tooltip>
      <GenericFormModal
        heading={heading}
        fields={fields as Field[]}
        handleClose={handleClose}
        isOpen={isOpen}
        maxWidth={maxWidth}
        onSubmit={async data => {
          await onSubmit?.(data);
          handleClose();
        }}
        validationSchema={validationSchema}
      >
        {children}
      </GenericFormModal>
    </Box>
  );
};
