import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableState } from 'mui-datatables';

import { useAuthPermission } from '@src/hooks/useAuthPermission';

import { Field } from '../FormController/type';

import { ActionMenu } from './ActionMenu';
import { CustomTableToolbar } from './CustomTableToolbar';
import {
  AddRowHandleProps,
  AddRowProps,
  ButtonConditions,
  DataTableProps,
  handleApplyColumnFilterType,
  handleFetchDataProps,
  TableDataType,
} from './type';

const Table = ({
  data: providedData = [],
  headers,
  count: providedCount,
  title = '',
  handleFetchData,
  handleUploadFile,
  tableActions,
  handleTableRowClick,
  addRowData,
  children,
  filterSchema,
  downloadSchemaLinks,
  tableOptions,
  isUserSynUp,
  getRefetch,
}: DataTableProps) => {
  const isAuthorized = useAuthPermission();
  const [count, setCount] = useState<number>();
  const [data, setData] = useState<TableDataType>([]);
  const [sortBy, setSortBy] = useState<{ sort_by: string; order_by: number }>();
  const [searchText, setSearchText] = useState<string>('');
  const [filterValues, setFilterValues] = useState<Record<string, string>>({});
  const { idKey, handleDelete, handleEdit, handleView, deleteMessage } = tableActions ?? {};
  const { t } = useTranslation();
  const { handleAddClick } = (addRowData as AddRowHandleProps) || {};

  const initialColumns = headers.filter(item => !item.hideInitially);
  const [filteredHeaders, setFilteredHeaders] = useState(initialColumns);

  const addRowDetails: AddRowProps = {
    ...addRowData,
    fields: (addRowData as AddRowProps)?.fields as Field[],
    onSubmit: async submitData => {
      await (addRowData as AddRowProps)?.onSubmit?.(submitData);
      fetchData({ rowsPerPage: 10, page: 0, searchText, filters: filterValues, sortBy: sortBy });
    },
  };

  const fetchData = useCallback(
    async ({ rowsPerPage, page, searchText: text, filters, sortBy: sortByFilter }: handleFetchDataProps) => {
      const res = await handleFetchData?.({
        rowsPerPage,
        page: page + 1,
        searchText: text,
        filters,
        sortBy: sortByFilter,
      });

      setData([...(res?.data || [])]);
      setCount(res?.count);
    },
    [handleFetchData]
  );

  const handleTableChange = async (action: string, tableState: MUIDataTableState) => {
    if (['changePage', 'changeRowsPerPage'].includes(action))
      await fetchData?.({ ...tableState, searchText, filters: filterValues, sortBy });
  };

  const handleApplyColumnFilter: handleApplyColumnFilterType = selectedColumns => {
    if (selectedColumns === 'reset') return setFilteredHeaders(initialColumns);

    const newSelectedColumns = headers.filter(header => selectedColumns[header.name]);
    setFilteredHeaders(newSelectedColumns);
  };

  useEffect(() => {
    if (!handleFetchData) return;

    fetchData({ rowsPerPage: 10, page: 0, searchText, filters: filterValues, sortBy });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, searchText, sortBy]);

  const handleDataFileUpload = async (file: FormData) => {
    try {
      await handleUploadFile?.(file);
      fetchData({ rowsPerPage: 10, page: 0, searchText, filters: filterValues, sortBy });
    } catch (error) {
      console.log('Error', error);
    }
  };

  const handleRowClick = (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
    const { rowIndex } = rowMeta;
    const row = data[rowIndex] as Record<string, string>;
    const id = idKey ? row[idKey] : row.id;

    if (handleTableRowClick) {
      handleTableRowClick(id);
    }
  };

  const defaultOptions: MUIDataTableOptions = {
    serverSide: true,
    selectableRows: 'none',
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    tableBodyHeight: '49vh',
    count: providedCount || count,
    onTableChange: handleTableChange,
    onRowClick: handleRowClick,
    customToolbar: () => (
      <CustomTableToolbar
        searchText={searchText}
        setSearchText={setSearchText}
        headers={headers}
        handleApplyColumnFilter={handleApplyColumnFilter}
        isUserSynUp={isUserSynUp}
        {...(handleUploadFile ? { handleUploadFile: handleDataFileUpload } : {})}
        {...([true, 'write', 'delete'].includes(isAuthorized) && addRowData
          ? { addRowData: { ...addRowDetails, handleAddClick } }
          : {})}
        applyFilters={filters => {
          setFilterValues(filters);
        }}
        filterCount={Object.keys(filterValues).length}
        filterSchema={filterSchema}
        downloadSchemaLinks={downloadSchemaLinks}
      >
        {children}
      </CustomTableToolbar>
    ),
    textLabels: {
      body: {
        noMatch: t('noDataFound'),
      },
    },
    onColumnSortChange: (changedColumn: string, direction: 'asc' | 'desc') => {
      setSortBy({
        sort_by: changedColumn,
        order_by: direction === 'asc' ? 1 : -1,
      });
    },
    ...tableOptions,
  };

  const refetch = () => {
    fetchData({ rowsPerPage: 10, page: 0, searchText, filters: filterValues, sortBy: sortBy });
  };

  useEffect(() => {
    if (!getRefetch) return;

    getRefetch(refetch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRefetch]);

  if (
    (handleDelete || handleEdit || handleView) &&
    !filteredHeaders.some(({ name }) => name === 'action') &&
    data.length
  ) {
    filteredHeaders.push({
      label: 'action',
      name: 'action',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const rowData = data[rowIndex] as Record<string, string>;

          return (
            <ActionMenu
              id={idKey ? rowData?.[idKey] : rowData?.id}
              handleView={handleView}
              deleteMessage={deleteMessage}
              refetch={refetch}
              {...getHasEditDelete(rowData)}
            />
          );
        },
      },
    });
  }

  const getHasEditDelete = (rowData: Record<string, string>) => {
    const obj: Record<string, ((id: string) => void) | string> = {};
    const hasEditButton = handleEdit && [true, 'write', 'delete'].includes(isAuthorized);
    const hasDeleteButton = handleDelete && [true, 'delete'].includes(isAuthorized);

    if (hasEditButton) {
      if (checkAllConditions(rowData, tableActions?.editButtonConditions)) {
        obj['handleEdit'] = handleEdit;
      }
    }

    if (hasDeleteButton) {
      if (checkAllConditions(rowData, tableActions?.deleteButtonConditions)) {
        obj['handleDelete'] = handleDelete;
      }

      if (tableActions?.deleteTextConditions) {
        const conditionsPassed = checkAllConditions(rowData, [tableActions.deleteTextConditions]);

        if (conditionsPassed) {
          obj['deleteText'] = tableActions.deleteTextConditions.matchText;

          if (tableActions.deleteTextConditions.overrideMatchMessage) {
            obj['overrideDeleteMessage'] = tableActions.deleteTextConditions.overrideMatchMessage;
          }
        } else {
          obj['deleteText'] = tableActions.deleteTextConditions.noMatchText;

          if (tableActions.deleteTextConditions.overrideNoMatchMessage) {
            obj['overrideDeleteMessage'] = tableActions.deleteTextConditions.overrideNoMatchMessage;
          }
        }
      }
    }

    return obj;
  };

  const checkAllConditions = (rowData: Record<string, string | boolean>, conditions?: ButtonConditions[]) => {
    if (!conditions) return true;

    let checksOut = true;

    for (const condition of conditions) {
      const { key, shouldNotBe, value } = condition;
      const isEqual = rowData?.[key] === value;

      if ((shouldNotBe && isEqual) || (!shouldNotBe && !isEqual)) {
        checksOut = false;
      }
    }

    return checksOut;
  };

  useEffect(() => {
    setFilteredHeaders(headers.filter(item => !item.hideInitially));
  }, [headers, data]);

  return (
    <MUIDataTable
      title={t(title)}
      data={providedData.length ? providedData : data}
      columns={filteredHeaders.map(header => ({
        ...header,
        ...(header?.label ? { label: t(header?.label || '') } : {}),
      }))}
      options={defaultOptions}
    />
  );
};

export default Table;
