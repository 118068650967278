import { TableHeaders } from '@src/components/Table/type';

export const tableHeaders: TableHeaders[] = [
  {
    label: 'homeRoom',
    name: 'home_room',
  },
  {
    label: 'assignedBy',
    name: 'assigned_by',
  },
  {
    label: 'assignedTo',
    name: 'assigned_to',
  },
  {
    label: 'accessExpirationDate',
    name: 'due_date',
  },
];
