import DetailCardHelmet from './DetailCardHelmet';
import DetailCardLayout from './DetailCardLayout';
import { StyledDivider } from './style';
import { DetailsCardProps } from './type';

const DetailsCard = ({ details, heading, onClick, isBackButton = false, children }: Partial<DetailsCardProps>) => {
  if (!heading) return <DetailCardLayout details={details} />;

  return (
    <div>
      <DetailCardHelmet {...{ isBackButton, heading, onClick }}>{children}</DetailCardHelmet>
      <StyledDivider />
      <DetailCardLayout details={details} />
    </div>
  );
};

export default DetailsCard;
