import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Box, IconButton } from '@mui/material';

import { useAuthPermission } from '@src/hooks/useAuthPermission';

import { StyledArrowBackIcon, StyledDetailHelmet, StyledEditIcon, StyledHeading } from './style';
import { DetailsCardProps } from './type';

const DetailCardHelmet = ({ isBackButton, heading, onClick, children }: DetailsCardProps) => {
  const { t } = useTranslation();
  const isAuthorized = useAuthPermission();
  const navigate = useNavigate();

  return (
    <StyledDetailHelmet>
      <StyledHeading>
        {isBackButton && (
          <div onClick={() => navigate(-1)}>
            <StyledArrowBackIcon fontSize='inherit' />
          </div>
        )}
        {t(heading)}
      </StyledHeading>
      {[true, 'write', 'delete'].includes(isAuthorized) && onClick ? (
        <Box sx={{ display: 'flex', gap: 2 }}>
          {children}
          <IconButton aria-label='edit' onClick={() => onClick()}>
            <StyledEditIcon />
          </IconButton>
        </Box>
      ) : null}
    </StyledDetailHelmet>
  );
};

export default DetailCardHelmet;
