import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export function AlertModal({
  isOpen,
  handleClose,
  handleAgree,
  heading,
  message,
}: {
  heading?: string;
  message: string;
  isOpen: boolean;
  handleClose: (event: React.MouseEvent<HTMLElement>) => void;
  handleAgree: (event: React.MouseEvent<HTMLElement>) => void | Promise<void>;
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      onClick={e => e.stopPropagation()}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      {heading && <DialogTitle id='alert-dialog-title'>{t(heading)}</DialogTitle>}
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{t(message)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='error'>
          {t('no')}
        </Button>
        <Button onClick={handleAgree} autoFocus color='info'>
          {t('yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
