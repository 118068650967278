import { TFunction } from 'i18next';
import moment from 'moment';
import * as Yup from 'yup';

export const validationSchema = (t: TFunction, isSuperAdmin: boolean) =>
  Yup.object().shape({
    ...(isSuperAdmin
      ? {
          assign_from: Yup.object()
            .nullable()
            .when('assign_to', (assign_to, schema) => {
              return assign_to
                ? schema.required(t('assignFromRequired')).notOneOf([Yup.ref('assign_to')], t('assignFromError'))
                : schema;
            }),
        }
      : {}),
    assign_to: Yup.object()
      .required(t('assignToRequired'))
      .notOneOf([Yup.ref('assign_from')], t('assignToError')),
    due_date: Yup.string()
      .nullable()
      .test('is-future-date', t('future-due-date'), function (value) {
        return value ? moment(value, 'YYYY-MM-DD').isAfter(moment().format('YYYY-MM-DD'), 'day') : true;
      }),
  });
