import { GroupBase, StylesConfig } from 'react-select';
import styled, { DefaultTheme } from 'styled-components';

import { Button, TextField } from '@mui/material';

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${({ theme }) => theme.space.get('s')};
`;

export const FormActionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled.label<{ error: boolean }>`
  color: ${({ error, theme }) => (error ? theme.colors.darkRed : theme.colors.mediumGray)};
`;

export const StyledButton = styled(Button)<{
  $isFullWidth?: boolean;
  $isCancel?: boolean;
  $submitButtonColor?: string;
}>`
  width: ${({ $isFullWidth }) => ($isFullWidth ? '100%' : 'fit-content')};
  margin-top: ${({ theme }) => theme.space.get('s')};
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledTextField = styled(TextField)`
  font-size: ${({ theme }) => theme.fontSize.get('m')};

  input[type='number'] {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-moz-focus-inner {
      border: 0;
    }
  }

  & label.Mui-focused {
    color: ${({ theme }) => theme.colors.lavenderMist};
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.colors.lavenderMist};
    }
  }
`;

export const StyledAddButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.fontSize.get('s')};
`;

export const StyledAddButton = styled.button`
  padding: ${({ theme }) => theme.space.get('2xs')} ${({ theme }) => theme.space.get('s')};
  border: none;
  background-color: ${({ theme }) => theme.colors.lightGreen};
  border-radius: ${({ theme }) => theme.fontSize.get('l')};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.get('s')};
  text-align: center;
`;

export const StyledAddText = styled.div`
  fontsize: ${({ theme }) => theme.fontSize.get('m')};
`;

export const customStyles = (
  theme: DefaultTheme,
  error?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): StylesConfig<any, boolean, GroupBase<any>> => ({
  control: (provided, state) => ({
    ...provided,
    fontSize: theme.fontSize.get('s'),
    borderColor: state.isFocused ? theme.colors.lavenderMist : error ? 'red' : provided.borderColor,
    '&:hover': {
      borderColor: state.isFocused ? theme.colors.lavenderMist : error ? 'red' : provided.borderColor,
    },
    boxShadow: state.isFocused ? `0 0 0 1px ${theme.colors.lavenderMist}` : provided.boxShadow,
  }),
  input: provided => ({
    ...provided,
    'input[type="number"]': {
      WebkitAppearance: 'none',
      MozAppearance: 'textfield',
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '&::-moz-focus-inner': {
        border: 0,
      },
    },
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 9999,
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'black',
    ...(state.isSelected ? { backgroundColor: theme.colors.lightBlue } : {}),
    '&:hover': {
      backgroundColor: theme.colors.lightBlue,
    },
  }),
});

export const StyledErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.darkRed};
  font-size: 12px;
  margin-top: 4px;
  margin-left: 15px;
`;

export const StyledSelectContainer = styled.div`
  position: relative;

  label {
    position: absolute;
    top: -9px;
    left: 15px;
    font-size: 12px;
    pointer-events: none;
    z-index: 1;
    background-color: white;
    padding: 0 5px;
  }
`;
