import { StyledLineContainer } from '@src/lib/styles/globalWrapper';

export const MiddleTextLine = ({ text }: { text: string }) => (
  <div>
    <StyledLineContainer>
      <img src='/icons/beforeLine.svg' alt='before-line' />
      {text}
      <img src='/icons/afterLine.svg' alt='after-line' />
    </StyledLineContainer>
  </div>
);
