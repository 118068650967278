import { Field, SelectOptionType } from '@src/components/FormController/type';

export const formSchema = (handleFilterChange: (column: string, checked: boolean) => void): Field[] => [
  {
    name: 'date',
    label: 'date',
    type: 'date',
    onChange: data => {
      const dateData = data as SelectOptionType;
      handleFilterChange(dateData.value, true);
    },
  },
];
