import React, { ChangeEvent, useContext, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { Attachment } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';

import { GlobalUIContext } from '@src/contexts/GlobalUIContext';
import { getFileType } from '@src/lib/helper';
import { UploadFileType } from '@src/lib/types';

import { LoaderWrapper, StyledContainer, StyledLabel, VisuallyHiddenInput } from './style';

const AttachFiles = ({
  handleUploadFile,
  uploadSingle = false,
  loading = false,
  uploadButtonText = 'attachFile',
  accept,
}: {
  handleUploadFile?: UploadFileType;
  uploadSingle?: boolean;
  loading?: boolean;
  uploadButtonText?: string;
  accept?: string;
}) => {
  const { setSnackValue } = useContext(GlobalUIContext);
  const { t } = useTranslation();
  const uniqueId = useId();

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (!files || files.length === 0) {
      return;
    }

    const formData = new FormData();
    const validFileTypes = accept?.replaceAll('.', '')?.split(',') || ['jpg', 'png', 'jpeg', 'webp', 'svg', 'pdf'];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileType = getFileType(file.name);

      if (!validFileTypes.includes(fileType)) {
        setSnackValue({
          message: 'Invalid file format. Please upload a jpg, png,jpeg, webp, svg, pdf or xlsx file.',
          type: 'error',
        });
        continue;
      }

      if (file?.size > 10000000) {
        setSnackValue({
          message: 'File size is too large. Please upload a file less than 10MB.',
          type: 'error',
        });
        continue;
      }

      formData.append('files', file);
    }

    handleUploadFile?.(formData);
  };

  if (!handleUploadFile) return null;

  return (
    <Button
      component='label'
      htmlFor={`icon-button-file-${uniqueId}`}
      variant='contained'
      disabled={loading}
      endIcon={<Attachment />}
    >
      <VisuallyHiddenInput
        id={`icon-button-file-${uniqueId}`}
        type='file'
        accept={accept || '.jpg, .png, .jpeg, .webp, .svg, .pdf'}
        multiple={uploadSingle ? undefined : true}
        onChange={handleFileChange}
        onClick={(event: React.MouseEvent<HTMLInputElement>) => {
          const input = event.target as HTMLInputElement;
          input.value = '';
        }}
      />
      <StyledContainer>
        {loading && (
          <LoaderWrapper>
            <CircularProgress size={24} />
          </LoaderWrapper>
        )}
        <StyledLabel>{t(uploadButtonText)}</StyledLabel>
      </StyledContainer>
    </Button>
  );
};

export default AttachFiles;
