import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SyncIcon from '@mui/icons-material/Sync';
import { Box, IconButton, Tooltip } from '@mui/material';

import { GlobalUIContext } from '@src/contexts/GlobalUIContext';
import { useAxiosApi } from '@src/hooks/useAxiosApi';
import useDebounce from '@src/hooks/useDebounce';

const rotateAnimation = {
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
};

export const TableSyncUserLayout = () => {
  const { t } = useTranslation();
  const { setSnackValue } = useContext(GlobalUIContext);
  const { handleSyncUserData } = useAxiosApi();
  const [isRotating, setIsRotating] = useState(false);

  const handleSyncUser = useDebounce(async () => {
    setIsRotating(true);

    try {
      setSnackValue({
        type: 'success',
        message:
          'Syncing user data. This process will take approximately 5 minutes. Please refresh the page after some time.',
      });
      await handleSyncUserData(false);
    } catch (error) {
      console.log('Error', error);
    } finally {
      setTimeout(() => setIsRotating(false), 1000);
    }
  }, 1000);

  return (
    <Box>
      <Tooltip title={t('syncUp')}>
        <IconButton onClick={handleSyncUser}>
          <SyncIcon
            sx={{
              color: 'inherit',
              animation: isRotating ? 'rotate 1s linear infinite' : 'none',
              ...rotateAnimation,
            }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
