import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import AppWrapper from './AppWrapper';

import './i18n/config';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <AppWrapper />
  </StrictMode>
);
