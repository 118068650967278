import styled from 'styled-components';

import { Button } from '@mui/material';

import { Breakpoints } from '@src/lib/styles/breakpoints';

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: ${({ theme }) => theme.space.get('s')};
  padding: ${({ theme }) => theme.space.get('s')};
  ${Breakpoints.DESKTOP_SMALL} {
    grid-template-columns: repeat(4, 1fr);
  }
  ${Breakpoints.TABLET} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${Breakpoints.MOBILE} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${Breakpoints.MOBILE_SMALL} {
    grid-template-columns: 1fr;
  }
`;

export const ContentContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.space.get('s')};
  border: 1px solid ${({ theme }) => theme.colors.deepGrayBorder};
`;

export const ProfileCard = styled.div`
  background: white;
  border-radius: ${({ theme }) => theme.space.get('xs')};
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.mistGray};
`;

export const ProfileHeader = styled.div`
  padding: calc(${({ theme }) => theme.space.get('m')} - 3px) ${({ theme }) => theme.space.get('s')}
    ${({ theme }) => theme.space.get('s')} ${({ theme }) => theme.space.get('s')};
  flex: 1;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mistGray};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.space.get('xs')};
`;

export const ProfileImage = styled.img<{ $height?: number; $width?: number }>`
  height: ${({ $height }) => $height || 90}px;
  width: ${({ $width }) => $width || 90}px;
  alt: 'studentProfile';
`;

export const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.get('2xs')};
`;

export const ProfileName = styled.div`
  font-weight: 600;
  font-size: calc(${({ theme }) => theme.fontSize.get('m')} - 2px);
  text-transform: capitalize;
`;

export const ProfileID = styled.div`
  font-weight: 500;
  font-size: calc(${({ theme }) => theme.fontSize.get('xs')} + 1px);
  color: grey;
`;

export const ProfileInfo = styled.div`
  padding: ${({ theme }) => theme.space.get('s')} calc(${({ theme }) => theme.space.get('m')} - 3px);
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.get('2xs')};
`;

export const InfoTitle = styled.div`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.get('s')};
  text-transform: capitalize;
`;

export const InfoDetail = styled.div`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.get('s')};
  text-transform: capitalize;
`;

export const InfoEmail = styled.div`
  display: flex;
  align-items: flex-start;
  font-weight: 500;
  font-size: calc(${({ theme }) => theme.fontSize.get('xs')} + 1px);
  color: grey;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
`;

export const SignUpContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  flex: 1;
  margin: calc(${({ theme }) => theme.space.get('s')} * -1) 0;
  ${Breakpoints.DESKTOP_SMALL} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const SignUpSidebar = styled.div`
  grid-column: 1/4;
  border-right: 1px solid ${({ theme }) => theme.colors.mistGray};
  padding: ${({ theme }) => theme.space.get('m')} calc(${({ theme }) => theme.space.get('m')} - 3px)
    ${({ theme }) => theme.space.get('m')} 0;

  ${Breakpoints.DESKTOP_SMALL} {
    padding: ${({ theme }) => theme.space.get('m')} 0;
    border-right: none;
  }
`;

export const SignUpActivityContent = styled.div`
  grid-column: 4/-1;
  display: flex;
  flex-direction: column;
  gap: calc(${({ theme }) => theme.space.get('m')} - 3px);
  & > div:first-child {
    display: flex;
    flex-direction: column;
    gap: calc(${({ theme }) => theme.space.get('m')} - 3px);
    flex: 1;
    padding: ${({ theme }) => theme.space.get('m')} 0 0 calc(${({ theme }) => theme.space.get('m')} - 3px);
  }
`;

export const ActivityFooter = styled.div`
  position: sticky;
  top: calc(${({ theme }) => theme.space.get('s')} * -1);
  padding: ${({ theme }) => theme.space.get('m')};
  border-top: 1px solid ${({ theme }) => theme.colors.mistGray};
  background: ${({ theme }) => theme.colors.offWhite};
`;

export const ParentViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: calc(${({ theme }) => theme.space.get('s')} * -1) 0;
  gap: ${({ theme }) => theme.space.get('s')};
  height: 100%;
  padding-top: ${({ theme }) => theme.space.get('s')};
`;

export const ParentProfileCard = styled.div`
  display: flex;
  border-radius: 10px;
  padding: ${({ theme }) => theme.space.get('s')};
  gap: ${({ theme }) => theme.space.get('m')};
  border: 1px solid ${({ theme }) => theme.colors.mistGray};
  background-color: ${({ theme }) => theme.colors.carrotOrange}22;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ParentProfileDetails = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.get('s')};
  width: fit-content;
`;

export const StudentName = styled.div`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.orange};
  font-size: calc(${({ theme }) => theme?.fontSize.get('s')} + 2px);
  font-weight: 600;
  text-transform: capitalize;
`;

export const StudentID = styled.div`
  font-size: calc(${({ theme }) => theme?.fontSize.get('xs')} + 1px);
  font-weight: 500;
`;

export const ParentInfoContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.get('s')};
  flex-wrap: wrap;
`;

export const ParentDetails = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.get('s')};
  flex-wrap: wrap;
`;

export const WeeklyScheduleContainer = styled.div`
  flex: 1;
  display: flex;
  background: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.deepGrayBorder};

  ${Breakpoints.DESKTOP_SMALL} {
    border: 0;
    border-radius: 0;
  }
`;

export const WeeklyScheduleHeader = styled.div`
  border-radius: 16px 16px 0 0;
  padding: ${({ theme }) => theme.space.get('m')} calc(${({ theme }) => theme.space.get('l')} + 8px);
  border-bottom: 0.5px solid #0000004d;
  display: flex;
  gap: ${({ theme }) => theme.space.get('s')};
  background: ${({ theme }) => theme.colors.offWhite};
  ${Breakpoints.DESKTOP_SMALL} {
    padding: ${({ theme }) => theme.space.get('m')} ${({ theme }) => theme.space.get('3xs')};
    flex-direction: column;
    border: 0;
    border-radius: 0;
  }
`;

export const WeeklyScheduleDayName = styled.div`
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: calc(${({ theme }) => theme?.fontSize.get('xs')} + 1px);
  ${Breakpoints.DESKTOP_SMALL} {
    font-weight: 700;
    text-align: left;
    font-size: ${({ theme }) => theme?.fontSize.get('xs')};
    color: ${({ theme }) => theme.colors.mediumGray};
    border-bottom: 1px solid ${({ theme }) => theme.colors.deepGrayBorder};
  }
`;

export const WeeklyScheduleContent = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.space.get('m')} 40px;
  ${Breakpoints.DESKTOP_SMALL} {
    padding: ${({ theme }) => theme.space.get('m')} ${({ theme }) => theme.space.get('3xs')};
  }
  flex: 1;
  & > div:last-child {
    & > div:last-child {
      border-right: none;
    }
  }
`;

export const WeeklyScheduleDayColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.get('s')};
  margin-left: ${({ theme }) => theme.space.get('xs')};
`;

export const WeeklyScheduleActivityList = styled.div`
  padding-right: ${({ theme }) => theme.space.get('xs')};
  border-right: 1px solid ${({ theme }) => theme.colors.mistGray};
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.get('s')};
  ${Breakpoints.DESKTOP_SMALL} {
    border: 0;
  }
`;

export const StyledSubmitHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  position: sticky;
  padding: ${({ theme }) => `${theme.space.get('2xs')} ${theme.space.get('2xl')}`};
  top: 0;
  margin: ${({ theme }) => `0 calc(${theme.space.get('2xl')} * -1)`};
  display: flex;
  justify-content: space-between;
  z-index: 3;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mistGray};
  ${Breakpoints.DESKTOP_SMALL} {
    padding: ${({ theme }) => `${theme.space.get('m')} ${theme.space.get('2xl')}`};
  }
`;

export const HeaderText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.get('s')};
  font-weight: 600;
  ${Breakpoints.DESKTOP_SMALL} {
    font-size: ${({ theme }) => theme.fontSize.get('m')};
  }
`;

export const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.colors.orange};
  text-transform: capitalize;
`;

export const NoActivityFound = styled.div`
  text-align: center;
  padding: ${({ theme }) => theme.space.get('s')};
  color: ${({ theme }) => theme.colors.mediumGray};
`;

export const StyledMobileSubmitWrapper = styled.div`
  position: sticky;
  padding: ${({ theme }) => theme.space.get('s')};
  bottom: calc(${({ theme }) => theme.space.get('s')} * -1);
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: end;
`;
