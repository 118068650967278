import { LoadingType } from '@src/contexts/GlobalUIContext';
import apiEndpoints from '@src/lib/apiEndpoints';

import apiRequest from './api';

export const sendPaymentReminder = async ({ program_id }: { program_id: string; isLoading?: LoadingType }) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.SEND_PAYMENT_REMINDER}/${program_id}`,
  });
};
