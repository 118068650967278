import { Field } from '@src/components/FormController/type';
import { User } from '@src/Models/user';

export const formSchema = (staffList: User[], homeRoomstaffList: User[], userDetail?: User): Field[] => [
  ...(userDetail?.role === 'super_admin'
    ? [
        {
          name: 'assign_from',
          label: 'Assign From',
          type: 'select',
          options: homeRoomstaffList.map(staff => ({
            label: `${staff.first_name} ${staff.last_name} (${staff.home_room})`,
            value: staff.id,
          })),
          required: true,
          gutter: {
            md: 6,
            sm: 4,
            xs: 4,
          },
        },
      ]
    : []),
  {
    name: 'assign_to',
    label: 'Assign To',
    type: 'select',
    options: staffList.map(staff => ({
      label: `${staff.first_name} ${staff.last_name}`,
      value: staff.id,
    })),
    required: true,
    gutter: {
      md: 6,
      sm: 4,
      xs: 4,
    },
  },
  {
    name: 'due_date',
    label: 'Due Date',
    type: 'date',
    required: false,
    gutter: {
      md: 6,
      sm: 4,
      xs: 4,
    },
  },
];
