import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Clear, Search as SearchIcon } from '@mui/icons-material';
import { Box, IconButton, InputAdornment } from '@mui/material';

import { GlobalUIContext } from '@src/contexts/GlobalUIContext';
import useDebounce from '@src/hooks/useDebounce';

import { Search, SearchIconWrapper, StyledInputBase } from '../style';
import { TableSearchLayoutProps } from '../type';

export const TableSearchLayout: React.FC<TableSearchLayoutProps> = ({ searchText, setSearchText }) => {
  const [searchValue, setSearchValue] = useState('');
  const { mode } = useContext(GlobalUIContext);
  const { t } = useTranslation();

  const handleClearSearch = () => {
    setSearchValue('');
    setSearchText('');
  };

  const handleSearchDebounce = useDebounce(value => {
    setSearchText(value as string);
  }, 500);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    handleSearchDebounce(event.target.value);
  };

  return (
    <Box>
      <Search $mode={mode}>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder={t('search') + '...'}
          value={searchValue}
          onChange={handleSearchChange}
          inputProps={{ 'aria-label': 'search' }}
          endAdornment={
            searchValue && (
              <InputAdornment position='end'>
                <IconButton onClick={handleClearSearch} aria-label='clear search'>
                  <Clear />
                </IconButton>
              </InputAdornment>
            )
          }
        />
      </Search>
    </Box>
  );
};
