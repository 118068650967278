import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { KeyboardArrowRight } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Checkbox, Divider, FormControlLabel, MenuItem, RadioGroup, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import FormController from '@src/components/FormController';

import { formSchema } from './formSchema';
import { BootstrapDialog, LeftContainer, RadioGroupWrapper, RightContainer } from './style';
import { FiltersType, TableFilterModalProps } from './type';

const TableFilterModal: React.FC<TableFilterModalProps> = ({ isOpen, handleClose, applyFilters, filterSchema }) => {
  const [selectedFilters, setSelectedFilters] = React.useState<FiltersType>({});
  const [dateValues, setDateValues] = React.useState<{ date: string }>();
  const filters = filterSchema?.filterOptions || [];

  const [selectedFilterModule, setSelectedFilterModule] = React.useState<number>(0);
  const { t } = useTranslation();

  const values = filters?.[selectedFilterModule]?.value;

  const handleFilterChange = (column: string, checked: boolean) => {
    const key = filters[selectedFilterModule].key;

    if (key === 'date') {
      setDateValues({ date: column });
      setSelectedFilters(prev => ({ ...prev, [key]: { [column]: true } }));
      return;
    }

    setSelectedFilters(prevSelected => {
      const updatedModule = { ...prevSelected[key] };

      if (checked) {
        updatedModule[column] = checked;
      } else {
        delete updatedModule[column];
      }

      const updatedFilters = {
        ...prevSelected,
        [key]: updatedModule,
      };

      if (!Object.keys(updatedFilters[key]).length) {
        delete updatedFilters[key];
      }

      return updatedFilters;
    });
  };

  const handleApplyFilters = () => {
    const customisedFilters: Record<string, string> = {};
    Object.keys(selectedFilters).forEach(filterKey => {
      const filterValues = selectedFilters[filterKey];

      customisedFilters[filterKey] = Object.keys(filterValues).join(',');
    });

    applyFilters(customisedFilters);
    handleClose();
  };

  const handleResetFilters = () => {
    setSelectedFilters({});
    applyFilters({});
    handleClose();
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollHeight, scrollTop, clientHeight } = event?.target as HTMLElement;

    const isAtBottom = scrollHeight - scrollTop - 10 <= clientHeight;

    if (isAtBottom) {
      filterSchema?.handleScrollToBottom?.(filters?.[selectedFilterModule].label || '');
    }
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={isOpen}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'>
        {t('filters')}
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers style={{ display: 'flex', padding: 0 }}>
        <LeftContainer>
          {filters?.map((item, index) => (
            <React.Fragment key={item?.key}>
              <MenuItem
                sx={{ p: 2, display: 'flex', justifyContent: 'space-between', paddingRight: 0 }}
                onClick={() => {
                  setSelectedFilterModule(index);
                }}
              >
                <Typography variant='body1' color='text.primary' style={{ textTransform: 'capitalize' }}>
                  {t(item?.label?.split('_').join(' '))}
                </Typography>
                <KeyboardArrowRight />
              </MenuItem>
              <Divider style={{ margin: 0 }} />
            </React.Fragment>
          ))}
        </LeftContainer>
        <RightContainer onScroll={handleScroll}>
          <RadioGroup>
            {typeof values === 'string' ? (
              <Box sx={{ p: 2 }}>
                <FormController fields={formSchema(handleFilterChange)} isSubmitButton={false} values={dateValues} />
              </Box>
            ) : values.length ? (
              values.map((column, i) => {
                const value = typeof column === 'string' ? column : column.value;
                const label = typeof column === 'string' ? column : column.label;

                return (
                  <RadioGroupWrapper key={value + i} sx={{ px: 2, py: 1 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedFilters[filters[selectedFilterModule].key]?.[value] || false}
                          onChange={event => handleFilterChange(value, event.target.checked)}
                        />
                      }
                      label={label}
                    />
                  </RadioGroupWrapper>
                );
              })
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                {t('noFilterDataFound')}
              </Box>
            )}
          </RadioGroup>
        </RightContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleResetFilters} color='error'>
          {t('reset')}
        </Button>
        <Button autoFocus onClick={handleApplyFilters} color='info'>
          {t('apply')}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default TableFilterModal;
